// assets
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import {
  CredentialsDetails,
  CredentialsListTrxn,
} from "../../services/srvc-credentials-realm";
import { ScoresUserDetails } from "../../services/srvc-users-scores";
import {
  UserDocumentDetail,
  UserDocumentList,
} from "../../services/srvc-media-docs-realm";

export default function DocumentsDetailsUserModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();
  const { id } = useParams();

  const [loader, setLoader] = useState(true);

  const [data, setData] = useState({});

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = await UserDocumentDetail({
          data: {
            item: id,
          },
        });

        if (result.stat) {
          setData(result.data);
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  if (loader) return <></>;
  return (
    <>
      {/* data */}
      {data && (
        <div>
          <>
            <div className="">
              <div className=" rounded-xd" >
                {data?.file?.mime.includes("pdf") && (
                  <iframe
                  style={{height:"500px"}}
                    className="w-100 rounded-xd"
                    src={data?.file?.link}
                    title="PDF Preview"
                    />
                )}

                {data?.file?.mime.includes("image") && (
                  <img
                    className="w-100 rounded-xd shadow"
                    src={data?.file?.link}
                    alt="..."
                  ></img>
                )}
              </div>

              <WebbDividerMedium />
            </div>
            <div className="back-color-wite p-3 rounded-xd">
              <p className="text-normal text-bold m-0 text-truncate">
                {data?.meta?.name}
              </p>
              <p className="text-small m-0 mb-2">{data?.meta?.number}</p>
            </div>
          </>
        </div>
      )}
    </>
  );
}
