// auth - firebase mail link
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import FormNeeded from '../webx/form-needed'
import WebbDividerMedium from '../webx/webb-divider-md'

import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm } from '../../services/srvc-utilities'

import { CheckDriverLicenseCard } from '../../services/srvc-broker-realm'
import { CredentialsCreate } from '../../services/srvc-credentials-realm'
import FileCreateCX from '../../services/srvc-filecreate-cweb-xx'
import { DocumentCreate } from '../../services/srvc-media-docs-realm'
const TAXXON = '1135d3cc4caf45c3abc811057b81126e7'

export default function AssetIDDriverLicenseModule (props) {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)

  const [docs, setDocs] = useState(false)
  const [form, setForm] = useState(false)
  const [code, setCode] = useState(false)
  const [check, setCheck] = useState(false)
  const [memo, setMemo] = useState('')

  const [item, setItem] = useState(props.data)
  const [data, setData] = useState({
    number: '',
    code: '',
    trxn: '',
    dob: ''
  })

  const [media, setMedia] = useState({ base64: '******', mime: '******' })

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        // const result = await TokenDetails({
        //   data: { item: id }
        // })
        // console.log('r', result)

        // if (result.stat) setData(result.data)

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [])

  useEffect(() => {
    setDocs(false)
    setForm(false)
    if (data.number !== '' && !code) setDocs(true)
    if (data.code !== '' && data.code.length == 6 && !isNaN(data.code))
      setForm(true)
  }, [data.number, data.code])

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  const handleCodeCreate = async () => {
    setCode(true)
    setMemo('OTP Sent to Mobile & Email')
  }

  const handleCodeCheck = async () => {
    setMemo('Please Wait...')
    setSubmit(true)
    var checkx = false
    if (data.code == '123456') {
      setMemo('Code Verification Success')
      checkx = true
    } else {
      setMemo('Code Verification Failed')
      checkx = false
    }
    setCheck(checkx)
    setSubmit(false)

    return checkx
  }

  const handleSubmit = async () => {
    const checkz = await handleCodeCheck()

    setMemo('Please Wait...')
    setSubmit(true)

    var checkdoc = { data: {}, stat: false }

    if (data.number == 'AZ9999000099123') {
      checkdoc = { data: {}, stat: true }
    } else {
      checkdoc = await CheckDriverLicenseCard({
        data: { number: data.number, birthDate: data.dob }
      })
    }
    // console.log (checkdoc)
    const media = await handleMediaUpload()
    let result
    let datx = {
      meta: { name: item.name, memo: item.name, item: item.item },
      taxxon: TAXXON,
      value: {
        number: data.number,
        name: asset.name,
        source: 'self',
        ...checkdoc.data
      },
      feature: {},
      media: {
        link: media?.file?.link,
        mime: media?.file?.mime,
        item: media?.item
      },
      status: { mint: true, check: true },
      user: { name: asset.name, mail: asset.mail, item: asset.item }
    }
    if (checkdoc.stat) {
      result = await CredentialsCreate({ data: datx, srvc: '******' })
      if (result.stat) {
        setMemo('Credential Created')
        setDone(true)
      } else {
        setMemo('Credential Creation Failed')
        setSubmit(false)
        setDone(false)
      }
    } else {
      setMemo(checkdoc.memo)
      setSubmit(false)
      setDone(false)
    }
  }

  const handleMediaUpload = async cred => {
    setMemo('Please Wait Uploading Files...')
    var datx = {
      filedata: media.base64,
      user: asset.item,
      taxxon: TAXXON,
      document: {
        name: data.name,
        number: '', //Date.now().toString(),
        format: '',
        sort: data.sort
      },
      dates: { start: Date.now(), end: Date.now() }
    }
    var res = await DocumentCreate({ data: datx })
    if (res.stat) {
      return res.data
    } else {
      setMemo('Error: Please Try Again Later')
    }
  }

  const handleMediaBanner = async filedata => {
    console.log(filedata)
    var mediax = { base64: filedata?.base64, mime: '' }
    setMedia(media => mediax)
    setDone(true)
  }

  if (loader) return <></>

  return (
    <>
      <div className='mb-3 p-2'>
        <label className='form-label text-small mx-3'>
          Upload Image <FormNeeded />
        </label>
        <FileCreateCX size='media-standard' media={handleMediaBanner} />
      </div>
      {/* data */}
      <div className='mx-3'>
        <div className='mb-3'>
          <label className='form-label small'>
            Enter Driver License Number <FormNeeded />
          </label>
          <input
            type='text'
            className='form-control height-md'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data.number}
            onChange={({ target }) => {
              handleChange('number', target.value)
            }}
            disabled={loader || submit || code}
            placeholder='Driving Licence Number'
          ></input>
        </div>
      </div>
      {/* Date of Birth */}
      <div className='mx-3'>
        <div className='mb-3'>
          <label className='form-label small'>
            Enter Date Of Birth <FormNeeded />
          </label>
          <input
            type='date'
            className='form-control height-md'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data.dob}
            onChange={({ target }) => {
              handleChange('dob', target.value)
            }}
            disabled={loader || submit || code}
            placeholder='Date Of Birth'
          ></input>
        </div>
      </div>

      {/* code */}
      <div className={code ? 'mx-3' : 'd-none'}>
        <div className='mb-3'>
          <label className='form-label small'>
            Enter OTP <FormNeeded />
          </label>
          <input
            type='text'
            className='form-control height-md'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data.code}
            onChange={({ target }) => {
              handleChange('code', target.value)
            }}
            disabled={loader || submit}
            placeholder='123456'
          ></input>
        </div>
      </div>

      {/* memo */}
      <div className='mx-3'>
        <p className='text-small m-0'>{memo}</p>
      </div>

      {/* action */}
      <div className='mx-3'>
        <WebbDividerMedium />
        <div className='d-flex justify-content-between'>
          <button
            className={`btn btn-light border back-color-wite rounded-xx text-small`}
            type='button'
            disabled={loader || submit}
            onClick={() => {
              navigate(-1)
            }}
          >
            {loader ? 'Please Wait...' : 'Cancel'}
          </button>

          <button
            className={`btn btn-primary border-none rounded-xx text-small ${
              code ? 'd-none' : ''
            }`}
            type='button'
            disabled={!docs || media.base64 == '******' || loader || submit}
            onClick={() => {
              handleCodeCreate()
            }}
          >
            {loader ? 'Please Wait...' : 'Get OTP'}
          </button>

          <button
            className={`btn btn-primary border-none rounded-xx text-small ${
              code ? '' : 'd-none'
            }`}
            type='button'
            disabled={!form || loader || submit}
            onClick={() => {
              handleSubmit()
            }}
          >
            {loader ? 'Please Wait...' : 'Continue'}
          </button>
        </div>

        <WebbDividerMedium />
      </div>
    </>
  )
}
