// user account
import React from "react";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from "react-jazzicon";

import WebbDividerSmall from "../webx/webb-divider-sm";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import { UserProfileDetails } from "../../services/srvc-users-realm";
import FormNeeded from "../webx/form-needed";

export default function UserScanDefaultModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const {id} = useParams()


  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();
  const [dataProfile, setDataProfile] = useState([]);



  useEffect(() => {
    // if (asset) {
      const fetchData = async () => {
        setLoader(true);
        // console.log("Profile Details")
        const result = await UserProfileDetails({
          data: {
            user: id,
          },
        });
        // console.log(result)
        if (result.stat) {
          // && resAssets.stat
          setDataProfile(result.data);
        }

        setLoader(false);
      };
      fetchData();
    // } else {
    // }
  }, []);

const generateVCard = () => {
  const vCardString = `
BEGIN:VCARD
VERSION:3.0
N:${dataProfile?.name || ""}
ORG:${dataProfile?.organization || ""}
TEL;TYPE=WORK:${dataProfile?.contact?.mobile || ""}
TEL;TYPE=CELL:${dataProfile?.contact?.mobile || ""}
EMAIL:${dataProfile?.mail || ""}
ADR;TYPE=Address:${dataProfile?.address?.city || ""};${dataProfile?.address?.state || ""};${dataProfile?.address?.country || ""};${dataProfile?.address?.pincode || ""}
URL;TYPE=instagram:${dataProfile?.sites?.instagram || ""}
URL;TYPE=facebook:${dataProfile?.sites?.facebook || ""}
URL;TYPE=linkedin:${dataProfile?.sites?.linkedin || ""}

END:VCARD`;

  const blob = new Blob([vCardString], { type: 'text/vcard' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'Contact.vcf';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

   

  return (
    <>
     {dataProfile?.length != 0 && (
      <div>
        <div className="back-color-wite rounded-xd p-3 border">
            <p
              className=" fw-bold text-color-tone m-0"
              style={{ fontSize: "20px" }}
            >
              Contact
            </p>
            <div className="me-auto">
              <div className="me-auto d-flex gap-4 my-2">
                <p className=" text-color-tone m-0">
                  <i class="bx bx-user-circle bx-sm"></i>
                </p>
                <p
                  className="text-color-next text-medium w-75 text-normal m-0 text-break
            "
                >
                  {dataProfile?.name || "******"}
                </p>
              </div>
              <div className="me-auto d-flex gap-4 my-2">
                <p className=" text-color-tone m-0">
                  <i class="bx bx-envelope bx-sm"></i>
                </p>
                <p
                  className="text-color-next text-medium w-75 text-normal m-0 text-break
            "
                >
                  {dataProfile?.contact?.mail}
                </p>
              </div>
              <div className="me-auto d-flex gap-4 my-2">
                <p className=" text-color-tone m-0">
                  <i class="bx bxl-whatsapp bx-sm"></i>
                </p>
                <p className="text-color-next text-medium text-normal m-0">
                  {dataProfile?.contact?.whatsapp}
                </p>
              </div>
              <div className="me-auto d-flex gap-4 my-2">
                <p className=" text-color-tone m-0">
                  <i class="bx bx-phone-call bx-sm"></i>
                </p>
                <p className="text-color-next text-medium text-normal m-0">
                  {dataProfile?.contact?.mobile}
                </p>
                
              </div>
            </div>
        </div>

        <div className="back-color-wite rounded-xd p-3 border mt-4">
              <div className="d-flex flex-column ">
                <div></div>
                <div className="me-auto w-100">
                  <p
                    className=" fw-bold text-color-tone m-0"
                    style={{ fontSize: "20px" }}
                  >
                    Address
                  </p>

                  <div className="me-auto d-flex gap-4 my-2">
                    <p className=" text-color-tone m-0">
                    <i class='bx bx-home-circle bx-sm' ></i>        
                                </p>
                    <p
                      className="text-color-next text-medium w-75 text-normal m-0 text-break
            "
                    >
                      {dataProfile?.address?.city},
                    {dataProfile?.address?.district},
                    {dataProfile?.address?.state} ,
                    {dataProfile?.address?.country}
                    </p>
                  </div>
                 
                </div>
              </div>
        </div>

        <div className="back-color-wite rounded-xd p-3 border mt-4">
              <div className="d-flex flex-column ">
                <div></div>

                <div className="me-auto ">
                  <p
                    className=" fw-bold text-color-tone m-0"
                    style={{ fontSize: "20px" }}
                  >
                    Social Media
                  </p>
                  <div className="me-auto d-flex gap-4 my-2">
                    <p className=" text-color-tone m-0">
                      <i class="bx bxl-instagram bx-sm"></i>
                    </p>
                    <p
                      className="text-color-next text-medium  text-normal m-0 text-break
            "
                    >
                        {dataProfile?.sites?.instagram}
                     
                    </p>
                  </div>
                  <div className="me-auto d-flex gap-4 my-2">
                    <p className=" text-color-tone m-0">
                      <i class="bx bxl-facebook bx-sm"></i>
                    </p>
                    <p
                      className="text-color-next text-medium  text-normal m-0 text-break
            "
                    >
                        {dataProfile?.sites?.facebook}
                      
                    </p>
                  </div>
                  <div className="me-auto d-flex gap-4 my-2">
                    <p className=" text-color-tone m-0">
                      <i class="bx bxl-linkedin bx-sm"></i>
                    </p>
                    <p
                      className="text-color-next text-medium  text-normal m-0 text-break
            "
                    >
                        {dataProfile?.sites?.linkedin}
                     
                    </p>
                  </div>
                </div>
                
              </div>
            </div>

      </div>
     )}

      {/* <div className="back-color-wite rounded-xd p-3 border">
        <p
          className=" fw-bold text-color-tone m-0"
          style={{ fontSize: "20px" }}
        >
          Contact
        </p>
        <div className="me-auto">
        <div className="d-flex">
                <div className="me-auto">
                  <p className="text-small text-color-tone m-0">
                    Name
                  </p>
                  <p className="text-color-next text-bold text-normal text-break m-0">
                    {dataProfile?.name || "******"}
                  </p>    
                </div>

                <div className=""></div>
              </div>
              <div className="d-flex">
                <div className="me-auto">
                  <p className="text-small text-color-tone m-0">
                    Email
                  </p>
                  <p className="text-color-next text-bold text-normal text-break m-0">
                    {dataProfile?.mail || "******"}
                  </p>    
                </div>
               

                <div className=""></div>
              </div>
              <div className="d-flex">
                <div className="me-auto">
                  <p className="text-small text-color-tone m-0">
                    Mobile Number
                  </p>
                  <p className="text-color-next text-bold text-normal text-break m-0">
                    {dataProfile?.mobile || "******"}
                  </p>    
                </div>

                <div className=""></div>
              </div>
          <div className="me-auto d-flex gap-4 my-2">
            <p className=" text-color-tone m-0">
              <i class="bx bx-envelope bx-sm"></i>
            </p>
            <p className="text-color-next text-bold text-break text-normal m-0">
              {dataProfile?.contact?.mail}
            </p>
          </div>
          <div className="me-auto d-flex gap-4 my-2">
            <p className=" text-color-tone m-0">
              <i class="bx bxl-whatsapp bx-sm"></i>
            </p>
            <p className="text-color-next text-bold text-normal m-0">
              {dataProfile?.contact?.whatsapp}
            </p>
          </div>
          <div className="me-auto d-flex gap-4 my-2">
            <p className=" text-color-tone m-0">
              <i class="bx bx-phone-call bx-sm"></i>
            </p>
            <p className="text-color-next text-bold text-normal m-0">
              {dataProfile?.contact?.mobile}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="back-color-wite rounded-xd p-3 border mt-4">
          <div className="d-flex flex-column ">
            <div></div>
            <div className="me-auto">
              <p
                className=" fw-bold text-color-tone m-0"
                style={{ fontSize: "20px" }}
              >
                Address
              </p>
              <p className="text-color-next text-bold text-normal m-0">
                {dataProfile?.address?.city},{dataProfile?.address?.pincode} ,{dataProfile?.address?.district},
                {dataProfile?.address?.state}, {dataProfile?.address?.country}
              </p>
            </div>
          </div>
        </div>

        <div className="back-color-wite rounded-xd p-3 border mt-4">
          <div className="d-flex flex-column ">
            <div></div>

            <div className="me-auto ">
              <p
                className=" fw-bold text-color-tone m-0"
                style={{ fontSize: "20px" }}
              >
                Social Media
              </p>
              <div className="d-flex  gap-4">
                <p className="text-color-next text-bold text-normal m-0 cursor">
                <a href={dataProfile?.sites?.instagram} target="_blank">

                  <i class="bx bxl-instagram bx-md"></i>
                  </a>
                </p>
                <p className="text-color-next text-bold text-normal m-0 cursor">
                <a href={dataProfile?.sites?.facebook} target="_blank">

                  <i class="bx bxl-meta bx-md"></i>
                  </a>
                </p>
                <p className="text-color-next text-bold text-normal m-0 cursor">
                <a href={dataProfile?.sites?.linkedin} target="_blank">

                  <i class="bx bxl-linkedin bx-md"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="mt-3 " style={{width:"15%"}}>
      <button className='btn btn-primary btn-sm text-small rounded-xx w-100'
      onClick={generateVCard}
      ><i class='bx bxs-download bx-sm' ></i></button>
      </div>
    </>
  );
}
