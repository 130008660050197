// auth - firebase mail link
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'
import FormNeeded from '../webx/form-needed'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { CredentialsCreate } from '../../services/srvc-credentials-realm'
import {
  CheckAadhaarCodeCheck,
  CheckAadhaarCodeCreate
} from '../../services/srvc-broker-realm'
import {
  GenerateAadharOTP,
  VerifyAadharOTP,
  VerifyMobile,
  CreateAbhaId
} from '../../services/srvc-nha-realm'

export default function HealthIDCreateModule (props) {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)

  const [docs, setDocs] = useState(false)
  const [form, setForm] = useState(false)
  const [code, setCode] = useState(false)
  const [check, setCheck] = useState(false)
  const [memo, setMemo] = useState('')
  const [info, setInfo] = useState({})

  const [item, setItem] = useState(props.data)
  const [data, setData] = useState({
    number: '',
    mobile: '',
    code: '',
    txnId: ''
  })

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        // const result = await TokenDetails({
        //   data: { item: id }
        // })
        // console.log('r', result)

        // if (result.stat) setData(result.data)

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [])

  useEffect(() => {
    setDocs(false)
    setForm(false)
    if (data.number !== '' && data.number.length === 12 && !code) setDocs(true)
    if (!data.mobile && data.mobile.length === 10) setDocs(true)
    if (data.code !== '' && data.code.length === 6 && !isNaN(data.code))
      setForm(true)
  }, [data.number, data.code, data.mobile])

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  const handleCodeCreate = async () => {
    setMemo('Please Wait ..')
    const input = {
      data: {
        aadhaar: data.number
      }
    }
    // if (data.number == '999900000123') {
    //   res = {
    //     data: { trxnId: Date.now.toString() },
    //     stat: true,
    //     memo: '',
    //     trxn: '',
    //     srvc: ''
    //   }
    // } else {
    //   res = await CheckAadhaarCodeCreate({
    //     data: { number: data.number }
    //   })
    //   // console.log(res)
    // }
    const res = await GenerateAadharOTP(input)

    if (res.data.txnId) {
      setData({ ...data, txnId: res.data.txnId })
      setMemo('OTP Sent to Aadhar Linked Mobile Number')
      setCode(true)
    } else {
      setMemo('Error: Please Check Aadhaar Number')
      setCode(false)
    }
  }

  const handleCodeCheck = async () => {
    setMemo('Please Wait...')
    setSubmit(true)
    let checkx = false
    const input = {
      data: {
        otp: data.code,
        txnId: data.txnId
      }
    }
    const res = await VerifyAadharOTP(input)
    if (!res?.stat) {
      setMemo('Something went wrong')
      return
    }
    //verify mobile
    const verifyMob = await VerifyMobile({
      data: {
        mobile: data.mobile,
        txnId: data.txnId
      }
    })
    if (!verifyMob.stat) {
      setMemo('Something went wrong')
      return
    }
    setMemo('Code Verification Success! Creating ABHA ID...')
    //create abha id
    const abhaRes = await CreateAbhaId({
      data: {
        txnId: data.txnId
      }
    })
    if (abhaRes?.data?.healthIdNumber) {
      setMemo('Success')
      checkx = true
      setInfo(res.data)
      handleSubmit(res.data)
    } else {
      setMemo('Code Verification Failed')
    }
    setSubmit(false)
  }

  const handleSubmit = async abhaResponse => {
    setMemo('Please Wait...')
    setSubmit(true)
    var datx = {
      meta: { name: item.name, memo: item.name, item: item.item },
      taxxon: item.taxxon,
      value: {
        number: abhaResponse.healthIdNumber,
        name: asset.name,
        source: 'self'
      },
      feature: {},
      status: { mint: true, check: true },
      user: { name: asset.name, mail: asset.mail, item: asset.item }
    }
    const result = await CredentialsCreate({ data: datx, srvc: '******' })
    console.log(result)
    if (result.stat) {
      setMemo('Credential Created')
      setDone(true)
    } else {
      setMemo('Credential Creation Failed')
      setSubmit(false)
      setDone(false)
    }
  }

  if (loader) return <></>

  return (
    <>
      {/* data */}
      <div className='mx-3'>
        <div className='mb-3'>
          <label className='form-label small'>
            Enter Aadhaar Card Number (12 digit) <FormNeeded />
          </label>
          <input
            type='text'
            className='form-control height-md'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data.number}
            onChange={({ target }) => {
              handleChange('number', target.value)
            }}
            disabled={loader || submit || code}
            placeholder='Aadhar no'
          ></input>
        </div>
      </div>
      <div className='mx-3'>
        <div className='mb-3'>
          <label className='form-label small'>
            Enter Mobile no <FormNeeded />
          </label>
          <input
            type='text'
            className='form-control height-md'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data.mobile}
            onChange={({ target }) => {
              handleChange('mobile', target.value)
            }}
            disabled={loader || submit || code}
            placeholder='Mobile no'
          ></input>
        </div>
      </div>

      {/* code */}
      <div className={code ? 'mx-3' : 'd-none'}>
        <div className='mb-3'>
          <label className='form-label small'>
            Enter OTP <FormNeeded />
          </label>
          <input
            type='text'
            className='form-control height-md'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data.code}
            onChange={({ target }) => {
              handleChange('code', target.value)
            }}
            disabled={loader || submit}
            placeholder='123456'
          ></input>
        </div>
      </div>

      {/* memo */}
      <div className='mx-3'>
        <p className='text-small m-0'>{memo}</p>
      </div>

      {/* action */}
      <div className='mx-3'>
        <WebbDividerMedium />
        <div className='d-flex justify-content-between'>
          <button
            className={`btn btn-light border back-color-wite rounded-xx text-small`}
            type='button'
            disabled={loader || submit}
            onClick={() => {
              navigate(-1)
            }}
          >
            {loader ? 'Please Wait...' : 'Cancel'}
          </button>

          <button
            className={`btn btn-primary border-none rounded-xx text-small ${
              code ? 'd-none' : ''
            }`}
            type='button'
            disabled={!docs || loader || submit}
            onClick={() => {
              handleCodeCreate()
            }}
          >
            {loader ? 'Please Wait...' : 'Get OTP'}
          </button>

          <button
            className={`btn btn-primary border-none rounded-xx text-small ${
              code ? '' : 'd-none'
            }`}
            type='button'
            disabled={!form || loader || submit}
            onClick={() => {
              handleCodeCheck()
            }}
          >
            {loader ? 'Please Wait...' : 'Continue'}
          </button>
        </div>

        <WebbDividerMedium />
      </div>
    </>
  )
}
