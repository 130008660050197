// assets
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbIcon from "../webx/webb-icon";

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { AccountsBaseBalance } from "../../services/srvc-accounts-realm";

const media = 'https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900'


export default function AssetsBharatIDModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState()

  useEffect( () => {

    if (asset){
      const fetchData = async() => {
      }
      fetchData()
    } else {}
  },[]);

  // if (loader) return <WebbLoaderSmall/>


  return (
  <>
    {/* info */}
    <div className="mb-3 d-none">
      <h2 className='text-lead m-0 mx-3'>{'Account Details'}</h2>
      <p className='text-normal m-0'></p>
    </div>
    
    {/* data */}
    <div className="rounded-xd text-color-wite shadow ratio ratio-16x9"
      style={{
        backgroundImage:`url(${media})`, 
        backgroundRepeat:'no-repeat', 
        backgroundSize:'cover',
        backgroundPosition: 'center center',
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
        // minHeight: '100vh'
        // height:'100vh'
      }}
    >
      <div className="rounded-xd p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>

      <div className="d-flex" style={{}}>
        <div className="d-none d-md-block">
          <WebbIcon data={{ color: 'text-color-wite', size: 'text-icon-wd'}}/>
        </div>
        <div className="d-md-none">
          <WebbIcon data={{ color: 'text-color-wite', size: 'text-icon-sm'}}/>
        </div>

        <div className="rounded-xx px-2 pt-1" style={{height: '2.5rem', width: '2.5rem'}}>
          {/* <WebbIcon data={{ color: 'text-color-wite', size: 'text-icon-sm'}}/> */}
        </div>
      </div>      

      <div className="d-none d-md-block">
        <WebbDividerMedium />
        <WebbDividerSmall /> 
        <p className="text-header-md m-0">{(asset?.number || '9999000000000456').match(/.{1,4}/g).join(" ")}</p>
      </div>

      <div className="d-md-none">
        <WebbDividerSmall />
        <p className="text-header-sm m-0">{(asset?.number || '9999000000000456').match(/.{1,4}/g).join(" ")}</p>
      </div>

      
      <div className="d-none d-md-block">
        <WebbDividerMedium /> 
      </div>
      
      <p className="text-normal text-sm text-bold text-uppercase m-0">{asset?.name || '******'}</p>
      <p className="m-0">{asset?.mail || '******'}</p>
      <p className="text-small m-0">Status: {asset?.active ? 'Active' : 'Inactive'}</p>
      </div>

    </div>

  </>

  )
}