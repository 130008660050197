// assets
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { TokenStatisticsUser } from "../../services/srvc-tokens-realm";
import { CredentialsStatisticsUser } from "../../services/srvc-credentials-realm";

export default function UserAssetStatisticsModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate()

  const [loader, setLoader] = useState(true);

  const [data, setData] = useState([

    {"name": "identiti", "code": "11", "icon": "bx bx-user-circle", "number": "0"},
    
    {"name": "finance", "code": "2", "icon": "bx bx-credit-card", "number": "0"},

    {"name": "members", "code": "31", "icon": "bx bx-badge-check", "number": "0"},
    {"name": "events", "code": "32", "icon": "bx bx-calendar", "number": "0"},
    
    {"name": "health", "code": "8", "icon": "bx bx-heart", "number": "0"},
    
    {"name": "work", "code": "7", "icon": "bx bx-briefcase", "number": "0"},
    {"name": "academics", "code": "4", "icon": "bx bx-dock-left", "number": "0"},
    {"name": "assets", "code": "6", "icon": "bx bx-buildings", "number": "0"},

  ])

  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);
        const result = await CredentialsStatisticsUser({data: {user: asset.item}, srvc: '******'})
        //console.log (result)
        const datx = data
        if (result.stat) setData(Array.from(datx, x => {return {
          ...x, 
          number: result.data.find(z => z.code == x.code).number
        }}))

        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  const handleClick = (item, number) => {
    if (number > 0) navigate(`/${asset.role}/assets/cx/${item}`)
  }

  // if (loader) return <></>


  return (
  <>

    {/* data */}
    <div className="rounded-xd back-color-wite px-2">
      <div className="mb-2"></div>
      <div className="row row-cols-4 g-2">
      {data && data.map((item, i) => (
        <div className="col text-center" key={i}>
        
          <div className="d-flex justify-content-center" onClick={() => handleClick(item.name, item.number)}>
            <div className=" position-relative">
              <i className={`${item.icon} text-icon-sm  back-color-lite p-2 rounded-xx cursor hidark`} 
                style={{width: '3rem', height: '3rem'}}>  
              </i>
              <span className={`position-absolute badge rounded-xx back-color-dark p-1 ${item.number == 0 ? 'd-none': ''}`}
                style={{ position: 'absolute', top: '0.1rem', right: '0rem', height: '1rem', minWidth: '1rem'}}
              >
                {item.number}
              </span>
            </div>

          </div>
          <div className="mt-1">
            <p className="text-mini text-uppercase">{item.name}</p>
          </div>

        </div>
      ))}
      </div>
    </div>

  </>

  )
}