// user account
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {QRCodeSVG} from 'qrcode.react';

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from '../webx/form-needed'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import listActions from '../../data.static/data-asset-actions.json'

export default function AssetActionsModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate()

  const [loader, setLoader] = useState(true);
  const [submit, setSubmit] = useState(false);

  const [actions, setActions] = useState([]);

  const [status, setStatus] = useState(props?.data?.status || {})
  const [data, setData] = useState({
    code: ''
  })

  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);
        
        var actionx = Array.from(listActions.data.filter(x => x.actv), z => 
          { return {...z, live: false}}
        )

        actionx = Array.from(actionx, x => { 
          var statux = {
            mint: props?.data?.status?.burn ? false :  props?.data?.status?.mint ? false : true,
            share: props?.data?.status?.burn ? false :  props?.data?.status?.mint ? true : false,
            code: props?.data?.status?.burn ? false :  props?.data?.status?.mint ? true : false,
            transfer: false,
            burn: false // props.data.status.mint ? !props.data.status.burn : false
          }
          return { ...x, live: statux[x.code]}
        })

        setActions(actionx)

        setLoader(false);
      }
      fetchData()
    } else {}
  },[status]);


  const handleClick = (status) => {
    // navigate(`/${asset.role}/assets/${item}`)
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }


  if (loader) return <></>


  return (
  <>

    {/* data */}
    <div className="rounded-xd back-color-wite p-2">
      <div className="mb-2"></div>
      <div className={`row row-cols-${actions.length} g-1`}>
      {actions && actions.map((item, i) => (
        <div className="col text-center" key={i}>
        
          <div className="d-flex justify-content-center">
            <div className="" data-bs-toggle='modal' data-bs-target={item.live ? `#${item.name}` : `#x`}>
              {/* data-bs-target={`#${item.name}`}  */}
              <i className={`${item.icon} text-icon-sm  back-color-lite p-2 rounded-xx  ${item.live ? 'cursor hidark' : 'text-color-tone'}`} 
                style={{width: '3rem', height: '3rem'}}>  
              </i>
            </div>

          </div>
          <div className="mt-1">
            <p className={`text-mini text-uppercase text-truncate m-0 ${item.live ? '' : 'text-color-tone'}`}>{item.name}</p>
          </div>

        </div>
      ))}
      </div>
    </div>

    {/* action */}
    <div className="modal fade" id="Mint" tabIndex="-1">
      <div className="modal-dialog">
        <div className='modal-content p-0 m-0 rounded-xd'>

          <div className='modal-header border-none'>
            <p className='text-normal align-middle m-0 p-0'>Mint Digital Asset</p>
            <button type='button' className='btn-close text-small' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>

          <div className='modal-body border-none'>
            <p className='text-normal text-bold m-0 text-truncate'>{props?.data?.name || '******'}</p>
            <p className='m-0'>{props?.data?.number || '******'}</p>

            <WebbDividerSmall/>
            <div className="mb-3">  
              <label className="form-label small">Enter Account Code <FormNeeded/></label>
              <input type="text" className="form-control height-md"
                style={{fontSize:'0.9rem', height:'2.7rem'}}
                value={data.code}
                onChange={({ target }) => {handleChange("code", target.value); }}
                disabled={loader || submit}
                placeholder="******">
              </input>
            </div>

          </div>

          <div className='d-flex modal-footer border-none'>
            <div className="">
              <button className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                data-bs-dismiss='modal'
                  // onClick={() => handleUnitCreateReset()}
              >Cancel</button>
            </div>
            
            <div className="ms-auto">
              <button
                className='btn btn-primary btn-sm text-small rounded-xx'
                  // onClick={() => handleUnitCreate()}
                disabled={loader || submit}
              >Submit</button>
            </div>
            
          </div>
        </div>
      </div>
    </div>    

    {/* action */}
    <div className="modal fade" id="Share" tabIndex="-1">
      <div className="modal-dialog">
        <div className='modal-content p-0 m-0 rounded-xd'>

          <div className='modal-header border-none'>
            <p className='text-normal align-middle m-0 p-0'>Share Digital Data</p>
            <button type='button' className='btn-close text-small' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>

          <div className='modal-body border-none'>
            <p className='text-normal text-bold m-0 text-truncate'>{props?.data?.name || '******'}</p>
            <p className='m-0'>{props?.data?.number || '******'}</p>

            <WebbDividerSmall/>
            <div className="mb-3">  
              <label className="form-label small">Enter Destination Email <FormNeeded/></label>
              <input type="text" className="form-control height-md"
                style={{fontSize:'0.9rem', height:'2.7rem'}}
                value={data.code}
                onChange={({ target }) => {handleChange("code", target.value); }}
                disabled={loader || submit}
                placeholder="******">
              </input>
            </div>
          </div>

          <div className='d-flex modal-footer border-none'>
            <div className="">
              <button className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                data-bs-dismiss='modal'
                  // onClick={() => handleUnitCreateReset()}
              >Cancel</button>
            </div>
            
            <div className="ms-auto">
              <button
                className='btn btn-primary btn-sm text-small rounded-xx'
                  // onClick={() => handleUnitCreate()}
                disabled={loader || submit}
              >Submit</button>
            </div>
            
          </div>
        </div>
      </div>
    </div>  

    {/* action */}
    <div className="modal fade" id="QR" tabIndex="-1">
      <div className="modal-dialog">
        <div className='modal-content p-0 m-0 rounded-xd'>

          <div className='modal-header border-none d-none'>
            <p className='text-normal align-middle m-0 p-0'>QR Code QuickShare</p>
            <button type='button' className='btn-close text-small' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>

          <div className='modal-body border-none'>
            <div className="text-center">
              <p className='text-normal text-bold m-0 text-truncate'>{props?.data?.name || '******'}</p>
              <p className='text-lead m-0'>{props?.data?.number || '******'}</p>
            </div>

            <WebbDividerSmall/>
            <div className="">  
              <div className="text-center border p-3 rounded-xd">
                <QRCodeSVG value={`iam.collect.${props.data.item}`} size={''} includeMargin={false} />
              </div>
              
            </div>
          </div>

          <div className='d-flex justify-content-center modal-footer border-none'>
            <div className="">
              <button className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                data-bs-dismiss='modal'
              >Close</button>
            </div>
          </div>
        </div>
      </div>
    </div> 

    {/* action */}
    <div className="modal fade" id="Transfer" tabIndex="-1">
      <div className="modal-dialog">
        <div className='modal-content p-0 m-0 rounded-xd'>

          <div className='modal-header border-none'>
            <p className='text-normal align-middle m-0 p-0'>Transfer Digital Asset</p>
            <button type='button' className='btn-close text-small' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>

          <div className='modal-body border-none'>
            ...
          </div>

          <div className='d-flex modal-footer border-none'>
            <div className="">
              <button className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                data-bs-dismiss='modal'
                  // onClick={() => handleUnitCreateReset()}
              >Cancel</button>
            </div>
            
            <div className="ms-auto">
              <button
                className='btn btn-primary btn-sm text-small rounded-xx'
                  // onClick={() => handleUnitCreate()}
                disabled={loader || submit}
              >Submit</button>
            </div>
            
          </div>
        </div>
      </div>
    </div>  

    {/* action */}
    <div className="modal fade" id="Burn" tabIndex="-1">
      <div className="modal-dialog">
        <div className='modal-content p-0 m-0 rounded-xd'>

          <div className='modal-header border-none'>
            <p className='text-normal align-middle m-0 p-0'>Burn (Delete) Data</p>
            <button type='button' className='btn-close text-small' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>

          <div className='modal-body border-none'>
            ...
          </div>

          <div className='d-flex modal-footer border-none'>
            <div className="">
              <button className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                data-bs-dismiss='modal'
                  // onClick={() => handleUnitCreateReset()}
              >Cancel</button>
            </div>
            
            <div className="ms-auto">
              <button
                className='btn btn-primary btn-sm text-small rounded-xx'
                  // onClick={() => handleUnitCreate()}
                disabled={loader || submit}
              >Submit</button>
            </div>
            
          </div>
        </div>
      </div>
    </div>              

    {/* blank modal */}
    <div className="modal fade d-none" id="x" tabIndex="-1">
      <div className="modal-dialog d-none"></div>
    </div>

  </>

  )
}