// assets
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import Slider from 'react-slick'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'

import NavsButtonBack from '../webx/navs-button-back'
import NavsButtonNext from '../webx/navs-button-next'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import listTokens from '../../data.static/data-asset-formats.json'
import listStatus from '../../data.static/data-transfers-status.json'

import { TokenListUserDomain } from '../../services/srvc-tokens-realm'
import { CredentialsListUserDomain } from '../../services/srvc-credentials-realm'

export default function HealthListUserIdentityModule (props) {
  // console.log(props.search)

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const { id } = useParams()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState([])
  const [list, setList] = useState()

  const [search, setSearch] = useState()

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [text, setText] = useState('')

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '24px',
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    swipeToSlide: true
    // afterChange: function(index) {
    //   console.log(
    //     `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
    //   );
    // }
  }

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const result = await CredentialsListUserDomain({
          data: {
            user: asset.item,
            domain: 'health.docs',
            index: index,
            items: items,
            filters: { name: props.search }
          }
        })
        console.log('creds', result)

        var tokenx = Array.from(
          listTokens.data.filter(
            z => z.taxxon.substring(0, 2) == '81' && z.active
          ),
          x => {
            return {
              meta: { name: x.name, memo: x.memo },
              media: x.media,
              number: x.number,
              taxx: x.taxxon,
              status: { mint: false },
              item: x.item
            }
          }
        )
        // setData(tokenx)

        if (result.stat) {
          tokenx = Array.from(tokenx, x => {
            // console.log (result.data.list.find(z => z.taxxon == x.taxx)?.number || '******')
            return {
              ...x,
              number:
                result.data.list.find(z => z.taxxon == x.taxx)?.number ||
                x.number,
              status:
                result.data.list.find(z => z.taxxon == x.taxx)?.status ||
                x.status
            }
          })
          setData(tokenx)
        }

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [props.search, index, items])

  const handleClick = item => {
    navigate(`/${asset.role}/health/id/${item}`)
  }

  console.log(data)

  if (loader)
    return (
      <>
        <div
          className='p-3 back-color-wite rounded-xd border align-middle'
          style={{}}
        >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-wait'></i>
          </span>
          <span className='ms-1 text-color-tone'>Please Wait...</span>
        </div>
      </>
    )
  if (!loader && (!data || data.length === 0))
    return (
      <>
        <div
          className='p-3 back-color-wite rounded-xd border align-middle'
          style={{}}
        >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-tint'></i>
          </span>
          <span className='ms-1 text-color-tone'>No Assets</span>
        </div>
      </>
    )

  return (
    <>
      <div className=''>
        {/* data */}
        <Slider {...settings}>
          {data &&
            data.map((item, i) => (
              <div key={i} className='px-1'>
                <div
                  className='media-card cursor'
                  onClick={() => handleClick(item.item)}
                >
                  <img
                    src={item?.media?.link}
                    className='img rounded-xd'
                    alt='...'
                  ></img>

                  <div className={`name`}>
                    <span className='text-small text-uppercase'>
                      {item?.meta?.name}
                    </span>
                  </div>

                  <div className='d-none d-md-block'>
                    <div className={`number`}>
                      <span className='text-header-sm text-bold'>
                        {item?.number
                          ? `XXXXXXXX-${item?.number.slice(-4)}`
                          : '0000000000000000'}
                        {/* .match(/.{1,4}/g).join(" ") */}
                      </span>
                    </div>
                    <div className={`user`}>
                      <span className='text-lead text-uppercase text-truncate'>
                        {asset?.name || '******'}
                      </span>
                    </div>
                  </div>

                  <div className='d-md-none'>
                    <div className={`number`}>
                      <span className='text-lead text-bold'>
                        {item?.number || '0000000000000000'}
                        {/* .match(/.{1,4}/g).join(" ") */}
                      </span>
                    </div>
                    <div className={`user`}>
                      <span className='text-uppercase text-truncate'>
                        {asset?.name || '******'}
                      </span>
                    </div>
                  </div>

                  <div className={`status w-100 text-center`}>
                    <div
                      className={`back-color-${
                        item?.status.mint ? 'success' : 'error'
                      } rounded-xd rounded-top-none p-1`}
                    >
                      <span className='text-small text-uppercase text-color-wite text-bold'>
                        {item?.status.mint ? 'Active' : 'Inactive'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </>
  )
}
