// auth - firebase mail link
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import WebbDividerSmall from '../webx/webb-divider-sm'

import listTokens from '../../data.static/data-asset-formats.json'
import listStatus from '../../data.static/data-transfers-status.json'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm } from '../../services/srvc-utilities'

import { CredentialsDetails } from '../../services/srvc-credentials-realm'
import HealthIDCreateModule from './health-create-id'
import HealthIDLinkModule from './health-link-id'
import AssetActionsModule from '../assets/assets-actions'

export default function HealthDetailsUserIdModule (props) {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const { id } = useParams()

  const [loader, setLoader] = useState(true)
  const [list, setList] = useState(listStatus.data)
  const [tokens, setTokens] = useState(listTokens.data)
  const [data, setData] = useState(listTokens.data.find(x => x.item == id))
  const [doc, setDoc] = useState('')
  const [actionBtnType, setActionBtnType] = useState('')

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const taxx = listTokens.data.find(x => x.item == id).taxxon
        const result = await CredentialsDetails({
          data: { item: taxx, user: asset.item }
        })

        if (result.stat)
          setData({
            ...data,
            number: result.data.number,
            status: result?.data?.status || { mint: false },
            item: result.data.item
          })

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [])

  const actionBtnClick = name => {
    setActionBtnType(name)
  }

  if (loader) return <></>

  return (
    <>
      {/* data */}
      <div className=''>
        <div className='media-card rounded-xd'>
          <img
            className='w-100 rounded-xd shadow'
            src={data.media.link}
            alt='...'
          ></img>

          <div className={`name`}>
            <span className='text-uppercase'>{data?.name || '******'}</span>
          </div>

          <div className='d-none d-md-block'>
            <div className={`number`}>
              <span className='text-header-sm text-bold'>
                {data?.number
                  ? `XXXXXXXX-${data?.number.slice(-4)}`
                  : '0000000000000000'}
              </span>
            </div>
            <div className={`user`}>
              <span className='text-lead text-uppercase text-truncate'>
                {asset.name || '******'}
              </span>
            </div>
          </div>

          <div className='d-md-none'>
            <div className={`number`}>
              <span className='text-lead text-bold'>
                {data?.number
                  ? `XXXXXXXX-${data?.number.slice(-4)}`
                  : '0000000000000000'}
                {/*  .match(/.{1,4}/g).join(" ") */}
              </span>
            </div>
            <div className={`user`}>
              <span className='text-normal text-uppercase text-truncate'>
                {asset.name || '******'}
              </span>
            </div>
          </div>
        </div>

        <div
          className={`rounded-xd rounded-top-none mx-3 back-color-${
            data?.status?.mint ? 'success' : 'error'
          }`}
        >
          <p className='text-uppercase text-small m-0 text-center text-color-wite p-2'>
            {data?.status?.mint ? 'active' : 'pending'}
          </p>
        </div>
      </div>

      <WebbDividerSmall />
      <div className='back-color-wite p-3 rounded-xd'>
        <p className='text-normal text-bold m-0 text-truncate'>
          {data?.name || '******'}
        </p>
        <p className='text-small m-0 mb-2'>
          {data?.number
            ? `XXXXXXXX-${data?.number.slice(-4)}`
            : '0000000000000000'}
        </p>

        <p className='m-0'>{data?.memo || '******'}</p>

        <div className=' d-none'>
          <WebbDividerSmall />
          <span className='text-small m-0 text-truncate'>
            Issuer: {data?.creator?.name || '******'}
          </span>
        </div>
      </div>
      {/* buttons */}
      <WebbDividerSmall />

      <div className={data?.status?.mint ? 'd-none' : ''}>
        <div className='mx-3'>
          <button
            className={`btn btn-${
              actionBtnType === 'link' ? 'primary' : 'light'
            } border rounded-xx text-small`}
            onClick={e => actionBtnClick('link')}
          >
            Link
          </button>
        </div>
        <WebbDividerSmall />
        <div className='mx-3'>
          <button
            className={`btn btn-${
              actionBtnType === 'create' ? 'primary' : 'light'
            } border rounded-xx text-small`}
            // disabled={loader || submit}
            onClick={e => actionBtnClick('create')}
          >
            Create
          </button>
        </div>
      </div>

      {/* auth action */}
      <div className={`${data?.status?.mint ? 'd-none' : 'mb-3'}`}></div>
      <div
        className={`${
          data?.status?.mint ? 'd-none' : 'rounded-xd back-color-wite'
        }`}
      >
        <div className='p-3' onClick={() => setDoc(data?.item)}>
          <p className='m-0'></p>
        </div>

        {id &&
          id === '1d2875805ae4402a8b4e208378c6bf345' &&
          actionBtnType === 'create' && <HealthIDCreateModule data={data} />}

        {id &&
          id === '1d2875805ae4402a8b4e208378c6bf345' &&
          actionBtnType === 'link' && <HealthIDLinkModule data={data} />}
      </div>

      {/* actions */}
      <WebbDividerSmall />
      <div className={`${data?.status?.mint ? '' : 'd-none'}`}>
        <AssetActionsModule
          data={{
            status: data.status,
            name: data.name,
            number: data.number,
            item: data.item
          }}
        />
      </div>
    </>
  )
}
