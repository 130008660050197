// main
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xw";
import WebbHeader from "../content/webz/webb-header-xw";
import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import WebbLoaderSmall from "../content/webx/webb-loader-sm";


import AssetsBharatIDModule from "../content/user/user-bharatid";
import UserAssetStatisticsModule from "../content/assets/assets-statistics-user";

import UserHomeActionsModule from "../content/user/user-home-actions";

import TransfersAssetListWaitUserModule from "../content/transfers.assets/transfers-list-wait";
import OffersListWait from "../content/offers/offers-list-wait";

export default function HomeUser () {
  
  const metadata = {
    name: 'Home',
    banner: {link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }


  return(

  <>
    <Helmet>
      <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
    </Helmet>

    <ContentFormat 
      
      name = {metadata.name}
      header = {{ size: 'small', show: true, data: 
        <> <WebbHeader data={{name: metadata.name, home: '/', link:''}} /> </> 
      }}
      media = {{ size: 'fluid', show: false, data: <></> }}

      content = {{ size: 'small', show: true, data: 
      <>

        <div className="mb-2"></div>
        <div className="back-color-wite p-1 rounded-xd">
          <AssetsBharatIDModule />
          <WebbDividerSmall />
          <UserAssetStatisticsModule />
        </div>

        <WebbDividerMedium />
        <div className="">
          <h2 className='text-normal m-0 ms-3'>{'Quick Actions'}</h2>
          <div className="mb-2"></div>
          <UserHomeActionsModule />
        </div>

        <WebbDividerMedium />
        <div className="">
          <h2 className='text-normal m-0 ms-3'>{'Pending Offers'}</h2>
          <div className="mb-2"></div>
          <OffersListWait />
        </div>
        
        <WebbDividerMedium />
        <div className="d-none">
          <h2 className='text-normal m-0 ms-3'>{'Pending Transfers'}</h2>
          <div className="mb-2"></div>
          <TransfersAssetListWaitUserModule />
        </div>


        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
      </>
      }}
    
      footer = {{ size: 'small', show: true, data: 
      <> 
        <div className="text-center back-color-lite">
          
          <WebbFooterMobile /> 
        </div>
      </> }}
    
    ></ContentFormat>


  </>
  )
}