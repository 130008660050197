// auth - firebase mail link
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import FormNeeded from '../webx/form-needed'
import WebbDividerMedium from '../webx/webb-divider-md'

import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm } from '../../services/srvc-utilities'

import {
  CheckAadhaarCodeCheck,
  CheckAadhaarCodeCreate
} from '../../services/srvc-broker-realm'
import { CredentialsCreate } from '../../services/srvc-credentials-realm'
import { VerifyAndLinkAbhaId } from '../../services/srvc-nha-realm'

export default function HealthIDLinkModule (props) {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)

  const [docs, setDocs] = useState(false)
  const [form, setForm] = useState(false)
  const [code, setCode] = useState(false)
  const [check, setCheck] = useState(false)
  const [memo, setMemo] = useState('')
  const [info, setInfo] = useState({})

  const [item, setItem] = useState(props.data)
  const [data, setData] = useState({
    number: '',
    code: '',
    trxn: ''
  })

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        // const result = await TokenDetails({
        //   data: { item: id }
        // })
        // console.log('r', result)

        // if (result.stat) setData(result.data)

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [])

  useEffect(() => {
    setDocs(false)
    setForm(false)
    if (data.number !== '' && data.number.length == 10 && !code) setDocs(true)
    if (data.code !== '' && data.code.length == 6 && !isNaN(data.code))
      setForm(true)
  }, [data.number, data.code])

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  const handleCodeCreate = async () => {
    setMemo('Please Wait ..')
    var res
    if (data.number == '999900000123') {
      res = {
        data: { trxnId: Date.now.toString() },
        stat: true,
        memo: '',
        trxn: '',
        srvc: ''
      }
    } else {
      res = await CheckAadhaarCodeCreate({
        data: { number: data.number }
      })
      // console.log(res)
    }

    if (res.stat) {
      setData({ ...data, trxn: res.data.trxnId })
      setMemo('OTP Sent to Linked Mobile Number')
      setCode(true)
    } else {
      setMemo('Error: Please Check Aadhaar Number')
      setCode(false)
    }
  }

  const handleCodeCheck = async () => {
    setMemo('Please Wait...')
    var res,
      checkx = false

    if (data.number == '999900000123') {
      if (data.code == '123456') {
        setSubmit(true)
        checkx = true
      }
    } else {
      res = await CheckAadhaarCodeCheck({
        data: { otp: data.code, transactionId: data.trxn }
      })

      setSubmit(true)
      if (res.stat) {
        setMemo('Code Verification Success')
        checkx = true
        setInfo(res.data)
      } else {
        setMemo('Code Verification Failed')
        checkx = false
      }
    }
    setSubmit(false)

    return { data: res?.data || {}, stat: checkx }
  }

  const handleSubmit = async res => {
    // const checkz = await handleCodeCheck()
    setMemo('Please Wait...')
    setSubmit(true)
    var result
    var datx = {
      meta: { name: item.name, memo: item.name, item: item.item },
      taxxon: item.taxxon,
      value: {
        number: res.healthIdNumber,
        name: asset.name,
        source: 'self'
        // ...checkz.data
      },
      feature: {},
      status: { mint: true, check: true },
      user: { name: asset.name, mail: asset.mail, item: asset.item }
    }
    result = await CredentialsCreate({ data: datx, srvc: '******' })

    if (result.stat) {
      setMemo('Credential Created')
      setDone(true)
    } else {
      setMemo('Credential Creation Failed')
      setSubmit(false)
      setDone(false)
    }
    setLoader(false)
  }

  const verifyAndLink = async () => {
    setMemo('Please Wait ...')
    setLoader(true)
    const input = {
      data: {
        mobile: data.number
      }
    }
    const res = await VerifyAndLinkAbhaId(input)
    if (res?.data?.healthIdNumber) {
      setMemo('Verification Successful')
      handleSubmit(res.data)
    } else {
      setMemo('ABHA id not found with this mobile no')
    }
    setLoader(false)
  }

  return (
    <>
      {/* data */}
      <div className='mx-3'>
        <div className='mb-3'>
          <label className='form-label small'>
            Enter Mobile Number (10 digit)
            <FormNeeded />
          </label>
          <input
            type='text'
            className='form-control height-md'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data.number}
            onChange={({ target }) => {
              handleChange('number', target.value)
            }}
            disabled={loader || submit}
            placeholder='mobile no'
          ></input>
        </div>
      </div>

      {/* code */}
      <div className={code ? 'mx-3' : 'd-none'}>
        <div className='mb-3'>
          <label className='form-label small'>
            Enter OTP <FormNeeded />
          </label>
          <input
            type='text'
            className='form-control height-md'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data.code}
            onChange={({ target }) => {
              handleChange('code', target.value)
            }}
            disabled={loader || submit}
            placeholder='123456'
          ></input>
        </div>
      </div>

      {/* memo */}
      <div className='mx-3'>
        <p className='text-small m-0'>{memo}</p>
      </div>

      {/* action */}
      <div className='mx-3'>
        <WebbDividerMedium />
        <div className='d-flex justify-content-between'>
          <button
            className={`btn btn-light border back-color-wite rounded-xx text-small`}
            type='button'
            disabled={loader || submit}
            onClick={() => {
              navigate(-1)
            }}
          >
            Cancel
          </button>

          <button
            className={`btn btn-primary border-none rounded-xx text-small ${
              code ? 'd-none' : ''
            }`}
            type='button'
            disabled={!docs || loader || submit}
            onClick={e => {
              e.preventDefault()
              verifyAndLink()
            }}
          >
            Verify & Link
          </button>
        </div>

        <WebbDividerMedium />
      </div>
    </>
  )
}
