// assets
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {QRCodeSVG} from 'qrcode.react';

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from '../webx/form-needed'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import listActions from '../../data.static/data-asset-actions.json'

import { AccountsMinterDetails } from '../../services/srvc-accounts-minter-realm'
import { TextSecure, TextReverse } from '../../services/srvc-encr-node'

import { TokenClaim, TokenTransfer } from "../../services/srvc-tokens-rand-realm";

import { CredentialStatusMintSet } from "../../services/srvc-credentials-realm";
import { CredentialStatusBurnSet } from "../../services/srvc-credentials-realm";
import { ModalViewAsetAction } from "../webx/modal-asset-action";



export default function AssetActionsModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate()
  const {id} = useParams()

  const [loader, setLoader] = useState(true)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)

  const [form, setForm] = useState(false);
  const [code, setCode] = useState(false)
  const [check, setCheck] = useState(false)

  const [memo, setMemo] = useState('Enter Code and Submit');

  const [account, setAccount] = useState({})

  const [actions, setActions] = useState([]);
  const [status, setStatus] = useState(props?.data?.status || {})
  const [cred, setCred] = useState(props?.data || {});
  const [data, setData] = useState({
    number: '',
    code: '',
    trxn: '',
    secret: ''
  })

  const [isModalOpen, setModalOpen] = useState(false);


  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);
        
        var actionx = Array.from(listActions.data.filter(x => x.actv), z => 
          { return {...z, live: false}}
        )

        actionx = Array.from(actionx, x => { 
          var statux = {
            mint: props.data.status.burn ? false :  props.data.status.mint ? false : true,
            share: props.data.status.burn ? false : props.data.status.mint ? true : false,
            code: props.data.status.burn ? false : props.data.status.mint ? true : false,
            transfer: false,
            burn: false // props.data.status.mint ? !props.data.status.burn : false
          }
          return { ...x, live: statux[x.code]}
        })

        setActions(actionx)

        setLoader(false);
      }
      fetchData()
    } else {}
  },[status]);


  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);

        const accountx = await AccountsMinterDetails({data: {user: asset.item}, srvc: '******'})
        // console.log (accountx)
        if (accountx.stat) {setAccount(accountx.data.account)}        

        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);        

  useEffect(() => {
    
    setForm(false)
    if (data.code !=="" && data.code.length == 6 && !isNaN(data.code)) 
    setForm(true)

  }, [data.code])


  const handleCodeCreate = async() => {
    setCode(true)
    setMemo('OTP Sent to Mobile & Email')
 
  }

  const handleCodeCheck = async() => {
    
    setMemo('Please Wait...')
    setSubmit(true)
    var checkx = false
    if (data.code == '123456') {
      setMemo('Code Verification Success')
      checkx = true

    } else {
      setMemo('Code Verification Failed')
      checkx = false
    }
    setCheck(checkx)
    setSubmit(false)
    
    return checkx
  }  

  const handleClick = (status) => {
    // navigate(`/${asset.role}/assets/${item}`)
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleMint = async() => {
    
    setLoader(true)
    setSubmit(true)
    // reverse
    // console.log(account)
    const mnemonix = await TextReverse( {text: account.secret, secret: data.secret})
    // console.log(mnemonix)

    var datx, result, res
    // claim
    datx = {    
      "account": { "number": "", "secret": mnemonix.data },
      "number": cred?.webx?.nmbr,
      "asset": cred?.webx?.item,
      "count": "1",
      "memo": "",
      "chain": cred?.webx?.ntwk
    }
    // console.log (datx)
    const claimx = await TokenClaim({data: datx, srvc: '******'})
    // console.log(claimx)

    if (claimx.stat) {
      setDone(true); 
      setSubmit(false);
    }

    // transfer
    datx = {
      "debit": {"number": "", "secret": ""},
      "credit": {"number": account.number, "secret": mnemonix.data},
      "number": cred?.webx?.nmbr,
      "asset": cred?.webx?.item,
      "count": "1",
      "memo": "",
      "chain": cred?.webx?.ntwk
    }
    // console.log (datx)
    const transferx = await TokenTransfer({data: datx, srvc: '******'})
    // console.log(transferx)


    setLoader(false);

  }

  const handleBurn = async() => {
    
    const checkz = await handleCodeCheck()
    
    setMemo('Please Wait...')
    setSubmit(true)

    var result
    var datx = { item: id, user: asset.item }
    
    if (checkz) {
    
      result = await CredentialStatusBurnSet({data: datx, srvc: '******'})
      // console.log (result)
      
      if (result.stat) {
        setStatus({...status, mint: true})
        setMemo('Credential Mint Success')
        setDone(true)
        
      } else {
        setMemo('Credential Mint Failed')
        setSubmit(false)
        setDone(false)
      }
    }

  }


  const openModal = () => { setModalOpen(true); };

  const closeModal = () => { setModalOpen(false) };

  const handleOnResult = async(item)=>{ 
    // handleMint()
    setModalOpen(false)
    
   }


  // if (loader) return <></>


  return (
  <>

    {/* data */}
    <div className="rounded-xd back-color-wite p-2">
      <div className="mb-2"></div>
      <div className={`row row-cols-${actions.length} g-1`}>
      {actions && actions.map((item, i) => (
        <div className="col text-center" key={i}>
        
          <div className="d-flex justify-content-center">
            <div className="" 
            data-bs-toggle='modal' 
            // onClick={()=> openModal() }
              data-bs-target={item.live ? `#${item.code}` : `#x`}
            >
              <i className={`${item.icon} text-icon-sm  back-color-lite p-2 rounded-xx  ${item.live ? 'cursor hidark' : 'text-color-tone'}`} 
                style={{width: '3rem', height: '3rem'}}>  
              </i>
            </div>

          </div>
          <div className="mt-1">
            <p className={`text-mini text-uppercase text-truncate m-0 ${item.live ? '' : 'text-color-tone'}`}>{item.name}</p>
          </div>

        </div>
      ))}
      </div>
    </div>

    {/* <ModalViewAsetAction isOpen={isModalOpen} onClose={closeModal} handleOnResult={handleOnResult} cred={cred} type="delete" ></ModalViewAsetAction> */}

    {/* action */}
    <div className="modal fade" id="mint" tabIndex="-1">
      <div className="modal-dialog">
        <div className='modal-content p-0 m-0 rounded-xd'>

          <div className='modal-header border-none d-none'>
            <p className='text-normal align-middle m-0 p-0'>Mint Digital Asset</p>
            <button type='button' className='btn-close text-small' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>

          <div className='modal-body border-none'>
          
            <div className="text-center">
              <p className='text-normal m-0'>{'Mint Asset'}</p>
              <WebbDividerSmall/>

              <p className='text-normal text-bold m-0 text-truncate'>{cred?.meta?.name || '******'}</p>
              <p className='m-0'>{cred?.number || '******'}</p>
            </div>
            
            <div className="">              
            </div>

            <WebbDividerSmall/>
            <div className="mb-3 text-center">  
              <label className="form-label small">Enter Account Code and Submit <FormNeeded/></label>
              <input type="text" className="form-control height-md text-center"
                style={{fontSize:'0.9rem', height:'2.7rem'}}
                value={data.secret}
                onChange={({ target }) => {handleChange("secret", target.value); }}
                disabled={loader || submit}
                placeholder="******">
              </input>
            </div>

            {/* memo */}
            <div className="text-center">
              <WebbDividerSmall />
              <p className="text-small m-0">{memo}</p>
            </div>

            {/* loader */}
            <div className={loader ? 'mx-3' : 'd-none'}>
              <div className="mb-2"></div>
              <div class="progress" role="progressbar" aria-label="..." aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" 
                style={{width: "100%", height: '0.5rem'}}>
                <div class="progress-bar progress-bar-striped progress-bar-animated" style={{width: "100%", height: '0.5rem'}}></div>
              </div>
            </div>
          </div>

          {/* action */}
          <div className={loader ? 'd-none' : 'mx-3'}>
            <button
              className='btn btn-primary btn-sm text-small rounded-xx w-100'
                onClick={() => handleMint()}
              disabled={loader || submit || data.secret == "" || data.secret.length !==6}
            >Submit</button>

            <div className="mb-1"></div>
            <button className='btn btn-outline-secondary btn-sm text-small rounded-xx w-100'
              data-bs-dismiss='modal'
            >Cancel</button>
            
            
          </div>

          <div className='modal-footer border-none'>
            
          </div>

        </div>
      </div>
    </div>    

    {/* action - share */}
    <div className="modal fade" id="share" tabIndex="-1">
      <div className="modal-dialog">
        <div className='modal-content p-0 m-0 rounded-xd'>

          <div className='modal-header border-none'>
            <p className='text-normal align-middle m-0 p-0'>Share Digital Data</p>
            <button type='button' className='btn-close text-small' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>

          <div className='modal-body border-none'>
            <p className='text-normal text-bold m-0 text-truncate'>{props?.data?.meta?.name || '******'}</p>
            <p className='m-0'>{props?.data?.number || '******'}</p>

            <WebbDividerSmall/>
            <div className="mb-3">  
              <label className="form-label small">Enter Destination Email <FormNeeded/></label>
              <input type="text" className="form-control height-md"
                style={{fontSize:'0.9rem', height:'2.7rem'}}
                value={data.code}
                onChange={({ target }) => {handleChange("code", target.value); }}
                disabled={loader || submit}
                placeholder="******">
              </input>
            </div>
          </div>

          <div className='d-flex modal-footer border-none'>
            <div className="">
              <button className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                data-bs-dismiss='modal'
                  // onClick={() => handleUnitCreateReset()}
              >Cancel</button>
            </div>
            
            <div className="ms-auto">
              <button
                className='btn btn-primary btn-sm text-small rounded-xx'
                  // onClick={() => handleUnitCreate()}
                disabled={loader || submit}
              >Submit</button>
            </div>
            
          </div>
        </div>
      </div>
    </div>  

    {/* action */}
    <div className="modal fade" id="code" tabIndex="-1">
      <div className="modal-dialog">
        <div className='modal-content p-0 m-0 rounded-xd'>

          <div className='modal-header border-none d-none'>
            <p className='text-normal align-middle m-0 p-0'>QuickShare</p>
            <button type='button' className='btn-close text-small' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>

          <div className='modal-body border-none'>
            <div className="text-center">
              <p className='text-normal text-bold m-0 text-truncate'>{props?.data?.meta?.name || '******'}</p>
              <p className='text-lead m-0'>{props?.data?.number || '******'}</p>
            </div>

            <WebbDividerSmall/>
            <div className="">  
              <div className="text-center border p-3 rounded-xd">
                <QRCodeSVG value={`iam.collect.${props.data.item}`} size={''} includeMargin={false} />
              </div>
              
            </div>
          </div>

          <div className='d-flex justify-content-center modal-footer border-none'>
            <div className="">
              <button className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                data-bs-dismiss='modal'
              >Close</button>
            </div>
          </div>
        </div>
      </div>
    </div> 

    {/* action */}
    <div className="modal fade" id="transfer" tabIndex="-1">
      <div className="modal-dialog">
        <div className='modal-content p-0 m-0 rounded-xd'>

          <div className='modal-header border-none'>
            <p className='text-normal align-middle m-0 p-0'>Transfer Digital Asset</p>
            <button type='button' className='btn-close text-small' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>

          <div className='modal-body border-none'>
            ...
          </div>

          <div className='d-flex modal-footer border-none'>
            <div className="">
              <button className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                data-bs-dismiss='modal'
                  // onClick={() => handleUnitCreateReset()}
              >Cancel</button>
            </div>
            
            <div className="ms-auto">
              <button
                className='btn btn-primary btn-sm text-small rounded-xx'
                  // onClick={() => handleUnitCreate()}
                disabled={loader || submit}
              >Submit</button>
            </div>
            
          </div>
        </div>
      </div>
    </div>  

    {/* action */}
    <div className="modal fade" id="burn" tabIndex="-1">
      <div className="modal-dialog">
        <div className='modal-content p-0 m-0 rounded-xd'>

          <div className='modal-header border-none'>
            <p className='text-normal align-middle m-0 p-0'>Delete (Burn) Data</p>
            <button type='button' className='btn-close text-small' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>

          <div className='modal-body border-none'>
            <p className='text-normal text-bold m-0 text-truncate'>{props?.data?.meta?.name || '******'}</p>
            <p className='m-0'>{props?.data?.number || '******'}</p>

            <WebbDividerSmall/>
            <div className="mb-3">  
              <label className="form-label small">Enter OTP (One Time Passcode) <FormNeeded/></label>
              <input type="text" className="form-control height-md"
                style={{fontSize:'0.9rem', height:'2.7rem'}}
                value={data.code}
                onChange={({ target }) => {handleChange("code", target.value); }}
                disabled={loader || submit}
                placeholder="******">
              </input>
            </div>
          </div>

          <div className='d-flex modal-footer border-none'>
            <div className="">
              <button className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                data-bs-dismiss='modal'
                  // onClick={() => handleUnitCreateReset()}
              >Cancel</button>
            </div>
            
            <div className="ms-auto">
              <button
                className='btn btn-primary btn-sm text-small rounded-xx'
                  onClick={() => handleBurn()}
                disabled={loader || submit}
              >Submit</button>
            </div>
            
          </div>
        </div>
      </div>
    </div>            

    {/* blank modal */}
    <div className="modal fade d-none" id="x" tabIndex="-1">
      <div className="modal-dialog d-none"></div>
    </div>

  </>

  )
}