import axios from 'axios'
import services from '../data.services/data-services-snbx.json'

const base = services.data.find(x => x.code == 'health.nha').link

export const VerifyAndLinkAbhaId = async item => {
  const basx = `${base}/services/search/mobile`
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  try {
    const result = await axios.post(basx, datx, { headers: head })
    return { ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const GenerateAadharOTP = async item => {
  const basx = `${base}/services/aadhaar/otp`
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  try {
    const result = await axios.post(basx, datx, { headers: head })
    return { ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const VerifyAadharOTP = async item => {
  const basx = `${base}/services/aadhaar/verify/otp`
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  try {
    const result = await axios.post(basx, datx, { headers: head })
    return { ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const VerifyMobile = async item => {
  const basx = `${base}/services/aadhaar/checkmobile/otp`
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  try {
    const result = await axios.post(basx, datx, { headers: head })
    return { ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const CreateAbhaId = async item => {
  const basx = `${base}/services/healthID/create`
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  try {
    const result = await axios.post(basx, datx, { headers: head })
    return { ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}
