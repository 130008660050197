// network
import { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderSmall from '../webx/webb-loader-sm';
import WebbModuleInfo from '../webx/webb-module-info'
import FormNeeded from '../webx/form-needed'

import FileCreateCX from "../../services/srvc-filecreate-cweb-xx";
import { GetUserForm } from '../../services/srvc-utilities';
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local';
import { DocumentCreate, DocumentSave } from '../../services/srvc-media-docs-realm';




const listDocs = [
  {name: 'Pan Card', code: 'taxc', item: '25782623593d44e4bff02487f03befe57'},
  {name: 'Aadhaar Card', code: 'adhr', item: '9001cd7381e745d19e55a1365a1f4b9e2'},
  {name: 'Utility Bill', code: 'util', item: '6661bb7e4c604488bb5d9442ff39ad939'}
]


export default function DocumentsCreateModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)
  
  const [form, setForm] = useState(false)
  const [memo, setMemo] = useState('')

  const [data, setData] = useState({
    name: '',
    mail: '',
    mobile: '',
    memo: '',
    sort: ''
  })

  const [media, setMedia] = useState({ base64: '******', mime: '******'})
  
  
  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoading(true);
        // console.log ('network')

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);

  const handleSubmit = async () => {
    setLoading(true)
    setSubmit(true)
    setMemo('Please Wait...')
    var datx = {
      filedata: media.base64,
      user: asset.item,
      document: {
        name:data.name,
        number:Date.now().toString(),
        format:"",
        sort: data.sort
      },
      dates:{start: Date.now(), end: Date.now()}
    }
    // console.log(datx)
    var res = await  DocumentCreate({data:datx})
    // console.log(res)
    if(res.stat){
      navigate('/user/assets')
    }
    else{
      setMemo("Error: Please Try Again Later")
    }

  }

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }


  const handleMediaBanner = async(filedata) => {
    // console.log (filedata);
    var mediax = {base64: filedata?.base64, mime: ''}
    setMedia(media => mediax);
    setDone(true)
  }


  // if (loading) { return (
  //   <div style={{ textAlign: 'center' }}>
  //     <WebbDividerMedium />
  //     <WebbLoaderSmall />
  //   </div>
  // )}

  return (
    <>
      {/* info */}
      <div className={submit ? 'mx-3' : 'mx-3'}>
        <WebbModuleInfo data={{ text: 'Create Digital Documents and Manage Online' }} />
      </div>    
      <WebbDividerSmall />

      {/* form */}
      <div className={submit ? 'mx-3' : 'mx-3'}>

        <div className='mb-3'>
          <label className='form-label small'>Document Category <FormNeeded /></label>
          <select onChange={({ target }) => { handleChange('sort', target.value)}} className="form-select height-md  "
            style={{ fontSize: '0.9rem', height: '2.7rem' }}>
            <option value="" selected>Open this select menu</option>
            <option value="identity">Identity</option>
            <option value="academics">Academics</option>
            <option value="work">Work</option>
            <option value="financial">Financial</option>
            <option value="social">Social</option>
            <option value="general">General</option>
            {/* <option value="7"></option> */}
            <option value="health">Health</option>
          </select>
        </div>

        <div className='mb-3'>
          <label className='form-label small'>Document Name <FormNeeded /></label>
          <input type='text'
            className='form-control height-md  '
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data.name}
            onChange={({ target }) => { handleChange('name', target.value)}}
            disabled={loading || submit}
            placeholder={`document name`}
          ></input>
        </div>

        <div className='mb-3 d-none'>
          <label className='form-label small'>Document Notes</label>
          <textarea 
            className="form-control" rows="6"
            style={{fontSize:'0.9rem'}}
            value={data.memo}
            onChange={({ target }) => {handleChange("memo", target.value); }}
            disabled={loading || submit}
            placeholder={`document notes ...`}
          ></textarea>
        </div>

        <div className="mb-3">
          <label className="form-label text-small">Add Media <FormNeeded/></label>
          <FileCreateCX size='media-standard'  media={handleMediaBanner} />
          
        </div>

      </div>
  
      <WebbDividerMedium />
      <div className="mx-3">
        <p className="m-0">{memo}</p>
      </div>

      {/* action */}
      <WebbDividerMedium />
      <div className={submit ? 'd-none' : 'mx-3'}>
        <div className="d-flex justify-content-between">

        <button className={`btn btn-light border back-color-wite rounded-xx button text-small`}
          type="button"
          disabled={loading || submit}
          onClick={()=> { navigate(`/${asset.role}/home`)}}
        >{loading ? 'Please Wait...' : 'Cancel'}</button>

        <button className={`btn btn-info border-none back-color-main text-color-wite rounded-xx text-small`}
          type="button"
          disabled={!done}
          onClick={()=> { handleSubmit()}}
        >{loading ? 'Please Wait...' : 'Continue'}</button>

        </div>

      </div>

      {/* status */}



    </>
  
    )
  }