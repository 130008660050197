// user account
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {QRCodeSVG} from 'qrcode.react';

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbIcon from "../webx/webb-icon";

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import list from '../../data.static/data-home-actions.json'

export default function UserActionsModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate()

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState()

  const [modalstatus, setModalStatus] = useState(false);
  const [modalaction, setModalAction] = useState('')

  const modalShow = () => { setModalStatus(true); };
  const modalClose = () => { setModalStatus(false) }; 

  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);
        setData(list.data.filter(x => x.actv))

        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);


  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);
        if (modalaction == "scan") {navigate(`/${asset.role}/scan`)}
        if (modalaction == "user") {navigate(`/${asset.role}/share/default`)}
        if (modalaction == "docs") {navigate(`/${asset.role}/document/upload`)}

        setLoader(false);
      }
      fetchData()
    } else {}
  },[modalaction]);


  const handleClick = (item) => {
    navigate(`/${asset.role}/assets/${item}`)
  }

  // console.log(data)

  if (loader) return <></>


  return (
  <>

    {/* data */}
    <div className="rounded-xd back-color-wite p-2">
      <div className="mb-2"></div>
      <div className={`row row-cols-${data.length} g-1`}>
      {data && data.map((item, i) => (
        <div className="col text-center mb-2" key={i}>
        
          <div className="d-flex justify-content-center"
            onClick={()=>{setModalAction(item.code); modalShow() }}
          >
            <div className=" position-relative">
              <i className={`${item.icon} text-icon-sm  back-color-lite p-2 rounded-xx  ${item.live ? 'cursor hidark' : 'text-color-tone'}`} 
                style={{width: '3rem', height: '3rem'}}>  
              </i>
              <span className={`position-absolute badge rounded-xx back-color-dark p-1`}
                style={{ position: 'absolute', top: '0.1rem', right: '0rem', height: '1rem', minWidth: '1rem'}}
              >{''}
              </span>
            </div>

          </div>
          <div className="mt-1">
            <p className={`text-mini text-uppercase text-truncate m-0 ${item.live ? '' : 'text-color-tone'}`}>{item.name}</p>
          </div>

        </div>
      ))}
      </div>
    </div>


    {/* actions -- code */}
    <div className='mb-1'></div>
    <div className={modalstatus && modalaction == "code" ? '' : 'd-none'}>
      <div className="modal-overlay mt-2" >

        <div className="modal-content rounded-xd  mt-5  " style={{marginTop:'0px',maxWidth:"35%" }} >

          {/* header */}
          {/* <div className='p-3 m-0 text-center d-none'>
            <div className='header text-bold m-0'>Quick Share</div>
          </div> */}
          {/* <WebbDividerSmall /> */}
          {/* content */}
          <div className='border-none scrollbar ' >
            
            <div className="text-center">
              <p className="text-lead text-bold m-0">{asset?.name || '******'}</p>
              <p className="text-normal m-0">{(asset?.number || '0000000000000000').match(/.{1,4}/g).join(" ")}</p>
            </div>
            
            <div className="text-center  rounded-xd p-3">
              <QRCodeSVG value={`iam.user.${asset.item}`} size={''} includeMargin={false} />
            </div>
          </div>

          {/* action */}
          <div className={'p-2'}>
            
              <button className='btn btn-outline-secondary border btn-sm text-small rounded-xx w-100'
                onClick={()=>modalClose()}        
            >Close</button>  
          </div>
        </div>

      </div>
    </div>

    {/* actions -- code */}
    <div className='mb-1'></div>
    <div className={modalstatus && ["scan", "user"].includes(modalaction) ? '' : 'd-none'}>
      <div className="modal-overlay">
        <div className="modal-content rounded-xd">

          {/* header */}
          <div className='p-3 m-0 text-center d-none'>
            <div className='header text-bold m-0'></div>
          </div>

          {/* content */}
          <div className='border-none scrollbar' >
            <WebbDividerMedium />
            <div className="text-center">
              <p className="text-normal m-0">Please Wait...</p>
            </div>
            <WebbDividerSmall />
            
          </div>

          {/* action */}
          <div className={'p-3'}>
            <WebbDividerSmall />
              <button className='btn btn-outline-secondary border btn-sm text-small rounded-xx w-100'
                onClick={()=>modalClose()}        
            >Cancel</button>  
          </div>

        </div>
      </div>
    </div>

  </>

  )
} 