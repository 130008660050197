// assets
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { AssetsDiscoverList } from '../../services/srvc-assets-realm'


export default function DiscoverListEventsModule (props) {
  // console.log(props.search)

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const {id} = useParams()

  const [loader, setLoader] = useState(true)
  
  const [sort, setSort] = useState()
  const [data, setData] = useState([])
  const [list, setList] = useState()

  const [search, setSearch] = useState()

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(100)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const datx = {
          user: asset.item, 
          domain: 'events',
          index: index, items: items, 
          filters: { name: props.search }
        }
        const result = await AssetsDiscoverList({ data: datx, srvc: '******'})
        //console.log('dis', result)

        if (result.stat) {
          
          setData(result.data.list)

          const total = result.data.count
          setTotal(result.data.count)

          setText(
            `${(index - 1) * items + 1} - ${
              index * items < total ? index * items : total
            } of ${total}`
          )
        }

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [props.search, index, items])


  const NextIndex = async () => {
    if (data.length < items) {
    } else {
      setNextIndex(curr + 1)
      setIndex(curr + 1)
      setCurrentIndex(curr + 1)
    }
  }

  const LastIndex = async () => {
    if (index == 1) {
    } else {
      setLastIndex(curr - 1)
      setIndex(curr - 1)
      setCurrentIndex(curr - 1)
    }
  }

  const handleClick = (item) => {
    const assetx = data.find(x => x.item == item)
    navigate(`/${asset.role}/discover/${assetx?.units?.list[0]?.item}`)
  }

  if (loader) return <>
  <div className='p-3 back-color-wite rounded-xd border align-middle' style={{}}>

    <span className="align-middle text-lead">
      <i className="bx bxs-info-circle text-color-wait"></i>
    </span>
    <span className='ms-1 text-color-tone'>Please Wait...</span>

  </div>
</>

  if (!loader && (!data || data.length === 0))
    return (
      <>
        <div
          className='p-3 back-color-wite rounded-xd border align-middle'
          style={{}}
        >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-tint'></i>
          </span>
          <span className='ms-1 text-color-tone'>No Events</span>
        </div>
      </>
    )

  return (
    <>
      {/* info */}
      <h2 className='text-normal m-0 ms-3'>{'Events'}</h2>
      <div className="mb-2"></div>

      {/* data */}
      <div className='d-flex mx-3 mb-2 text-small'>
        <div className=''>Showing 1-{total > items ? items : total} of {total}</div>
        <div className='ms-auto text-end'></div>
      </div>

      <div className='back-color-wite rounded-xd p-2'>
      {data && data.map((item, i) => (
        <div className='' key={i}>

          <div className='d-flex p-1 rounded-xd cursor' onClick={() => handleClick(item.item)}>
            <div className='' style={{minWidth: '4.2rem', minHeight: '4.2rem'}}>
              <div className='media-cube'>
                <img src={item.media.link} className='rounded-xd' alt="" ></img>
              </div>
            </div>

            <div className='px-1 ms-2'>
              <p className='text-bold m-0 text-sm'>{item.meta.name || '******'}</p>
              <p className='text-small m-0 mb-1 d-none'>{item?.webx?.number || '******'}</p>
              <p className='text-small m-0 mb-1 '>{new Date(item?.date?.start).toLocaleString() || '******'}</p>
              <p className='text-small m-0'>{item.creator.name || '******'}</p>
            </div>

            <div className='ms-auto'>
              <div className={`vr m-2 rounded-xd opacity-75 d-none back-color-${item?.status?.mint && !item?.status?.burn ? 'success' : 'error'}`} 
                style={{width: '.25rem', height: '79%'}}>

              </div>
            </div>
          </div>
          
          <div className={`d-flex p-1 ${i<data.length-1 ? '' : 'd-none'}`}>
            <div className='' style={{width: '0'}}></div>
            <div className='w-100'>
              <div className='border-bottom mx-2'></div>
            </div>
          </div>

        </div>
      ))}
      </div>

    </>
  )
}
