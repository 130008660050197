
// assets
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'


import { CredentialsListUserDomain } from '../../services/srvc-credentials-realm';

import listStatus from '../../data.static/data-transfers-status.json'
import listTokens from '../../data.static/data-asset-formats.json'


export default function CredentialsListUserModule (props) {
  

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const {id} = useParams()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState([])
  const [list, setList] = useState(listStatus.data)

  const [search, setSearch] = useState()

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "24px",    
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    swipeToSlide: true,
    // afterChange: function(index) {
    //   console.log(
    //     `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
    //   );
    // } 
  };

  var tokenx = Array.from(listTokens.data.filter(z=> z.taxxon.substring(0,2) == '11' && z.active), x => { return {
    meta: {name: x.name, memo: x.memo},
    media: x.media,
    number: x.number,
    taxx: x.taxxon,
    status: {mint: false},
    item: x.item,
  }})

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const result = await CredentialsListUserDomain({
          data: {
            user: asset.item,
            domain: id,
            index: index,
            items: items,
            filters: { name: props.search }
          }
        })
        console.log('r', result)

        if (result.stat) {
          // && resAssets.stat
          setData(result.data.list)

          const resTotal = result.data.count //+resAssets.data.count
          setTotal(result.data.count)

          setText(
            `${(index - 1) * items + 1} - ${
              index * items < resTotal ? index * items : resTotal
            } of ${resTotal}`
          )
        }

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [props.search, index, items])

  const NextIndex = async () => {
    if (data.length < items) {
    } else {
      setNextIndex(curr + 1)
      setIndex(curr + 1)
      setCurrentIndex(curr + 1)
    }
  }

  const LastIndex = async () => {
    if (index == 1) {
    } else {
      setLastIndex(curr - 1)
      setIndex(curr - 1)
      setCurrentIndex(curr - 1)
    }
  }

  const handleClick = (item,name) => {
    if(id=="identiti"){
      const itemID = tokenx.find(obj => obj.meta.name === name).item;
      navigate(`/${asset.role}/assets/id/${itemID}`)
    }
    else{
      navigate(`/${asset.role}/assets/${item}`)

    }
  }
  console.log(tokenx)

  if (loader)
    return (
      <>
        <div
          className='p-3 back-color-wite rounded-xd border align-middle'
          style={{}}
        >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-tint'></i>
          </span>
          <span className='ms-1 text-color-tone'>Please Wait...</span>
        </div>
      </>
    )

  if (!loader && (!data || data.length === 0))
    return (
      <>
        <div
          className='p-3 back-color-wite rounded-xd border align-middle'
          style={{}}
        >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-tint'></i>
          </span>
          <span className='ms-1 text-color-tone'>No Credentials</span>
        </div>
      </>
    )

  return (
    <>
      {/* data-list */}
      <div className='d-flex mx-3 mb-2 text-small d-none'>
        <div className=''>Showing 1-5 of {total}</div>
        <div className='ms-auto text-end'></div>
      </div>

      <div className={`back-color-wite rounded-xd p-2 ${id=="identiti"?"d-none":""}`}>
      {data && data.map((item, i) => (
        <div className='' key={i}>

          <div className='d-flex p-1 rounded-xd cursor' onClick={() => handleClick(item.item)}>
            <div className='' style={{width: '4.2rem', height: '4.2rem'}}>
              <div className='media-cube'>
                <img src={item.media.link} className='rounded-xd' alt="" ></img>
              </div>
            </div>

            <div className='px-1 ms-2'>
              <p className='text-bold m-0'>{item.meta.name || '******'}</p>
              <p className='text-small m-0 mb-1'>{item.number || '******'}</p>
              <p className='text-small m-0'>From :  {(new Date(parseInt(item?.dates?.start))).toLocaleString() || '******'}</p> 
              <p className='text-small m-0'>To : {(new Date(parseInt(item?.dates?.end))).toLocaleString() || '******'}</p>
            </div>

            <div className='ms-auto'>
              <div className={`vr m-2 rounded-xd opacity-75 back-color-${item?.status?.mint && !item?.status?.burn ? 'success' : 'error'}`} 
                style={{width: '.25rem', height: '79%'}}>

              </div>
            </div>
          </div>
          
          <div className={`d-flex p-1 ${i<data.length-1 ? '' : 'd-none'}`}>
            <div className='' style={{width: '0'}}></div>
            <div className='w-100'>
              <div className='border-bottom'></div>
            </div>
          </div>

        </div>
      ))}
      </div>

      <div className={`back-color-wite rounded-xd  ${id=="identiti"?"":"d-none"}`}>
     
         {data && data.map((item, i) => (
        <div className='' key={i}>

          <div className='d-flex p-1 rounded-xd cursor' onClick={() => handleClick(item.item,item.meta.name)}>
            <div className='' style={{width: '4.2rem', height: '4.2rem'}}>
              <div className='media-cube'>
              <img src="https://img.freepik.com/free-photo/vivid-blurred-colorful-wallpaper-background_58702-3773.jpg?w=600" className='img rounded-xd' alt="..."></img>
              </div>
              </div>

            <div className='px-1 ms-2'>
              <p className='text-bold m-0'>{item.meta.name || '******'}</p>
              <p className='text-small m-0 mb-1'>{item.number || '******'}</p>
            </div>

            <div className='ms-auto'>
              <div className={`vr m-2 rounded-xd opacity-75 back-color-${item?.status?.mint && !item?.status?.burn ? 'success' : 'error'}`} 
                style={{width: '.25rem', height: '79%'}}>

              </div>
            </div>
          </div>
          
          <div className={`d-flex p-1 ${i<data.length-1 ? '' : 'd-none'}`}>
            <div className='' style={{width: '0'}}></div>
            <div className='w-100'>
              <div className='border-bottom'></div>
            </div>
          </div>

        </div>
      ))}
        </div>
      
      {/* data-cards */}

    </>
  )
}
