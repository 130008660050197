// main
import { Helmet } from 'react-helmet'

import ContentFormat from '../content/webz/content-format-xw'
import WebbHeader from '../content/webz/webb-header-xw'
import WebbFooterMobile from '../content/webz/webb-footer-mobile'

import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'
import WebbLoaderSmall from '../content/webx/webb-loader-sm'

import AssetListUserIdentitiModule from '../content/assets/assets-list-identiti'
import AssetListUserAcademicsModule from '../content/assets/assets-list-academics'
import AssetListUserWorkModule from '../content/assets/assets-list-work'
import AssetListUserSocialMembersModule from '../content/assets/assets-list-social-members'
import AssetListUserSocialEventsModule from '../content/assets/assets-list-social-events'
import DocumentsListUserModule from '../content/documents/document-list-user'
import HealthListUserIdentityModule from '../content/health/health-list-identity'

export default function Health () {
  const metadata = {
    name: 'Health',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel='canonical' href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{
          size: 'small',
          show: true,
          data: (
            <>
              {' '}
              <WebbHeader
                data={{ name: metadata.name, home: '/', link: '' }}
              />{' '}
            </>
          )
        }}
        media={{ size: 'fluid', show: false, data: <></> }}
        content={{
          size: 'small',
          show: true,
          data: (
            <>
              <div className='mb-2'></div>
              <h2 className='text-normal m-0 ms-3'>{'Digital IDs'}</h2>
              <WebbDividerSmall />
              <HealthListUserIdentityModule />

              <div className='mb-2 mt-4'></div>
              <h2 className='text-normal m-0 ms-3'>{'Documents & Media'}</h2>
              <WebbDividerSmall />
              {/* <DocumentsListUserModule /> */}

              <WebbDividerMedium />
              <h2 className='text-normal m-0 ms-3'>{'Work & Internships'}</h2>
              <WebbDividerSmall />
              {/* <AssetListUserWorkModule /> */}

              <WebbDividerMedium />
              <h2 className='text-normal m-0 ms-3'>{'Events (Social)'}</h2>
              <WebbDividerSmall />
              {/* <AssetListUserSocialEventsModule /> */}

              <WebbDividerMedium />
              <h2 className='text-normal m-0 ms-3'>{'Memberships (Social)'}</h2>
              <WebbDividerSmall />
              {/* <AssetListUserSocialMembersModule /> */}

              <WebbDividerMedium />
              <h2 className='text-normal m-0 ms-3'>
                {'Academics & Cerificates'}
              </h2>
              <WebbDividerSmall />
              {/* <AssetListUserAcademicsModule /> */}

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          )
        }}
        footer={{
          size: 'small',
          show: true,
          data: (
            <>
              <div className='text-center back-color-lite'>
                <WebbFooterMobile />
              </div>
            </>
          )
        }}
      ></ContentFormat>
    </>
  )
}
