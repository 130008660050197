// main
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xx";
import WebbHeader from "../content/webz/webb-header-xx";
import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import WebbLoaderSmall from "../content/webx/webb-loader-sm";

import CredentialsListUserModule from "../content/creds/creds-list";


const list = [
  {"name": "Identity", "code": "identiti", "icon": "bx bx-user-circle", "number": "0"},
  {"name": "Financial", "code": "finance", "icon": "bx bx-credit-card", "number": "0"},
  {"name": "Membership", "code": "members", "icon": "bx bx-badge-check", "number": "0"},
  {"name": "Event", "code": "events", "icon": "bx bx-calendar", "number": "0"},
  {"name": "Health", "code": "health", "icon": "bx bx-heart", "number": "0"},
  {"name": "Work", "code": "work", "icon": "bx bx-briefcase", "number": "0"},
  {"name": "Academics", "code": "academics", "icon": "bx bx-dock-left", "number": "0"},
  {"name": "Real World Assets", "code": "assets", "icon": "bx bx-buildings", "number": "0"},
]

export default function CredsListUser () {
  
  const metadata = {
    name: 'Assets',
    banner: {link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }

  const {id} = useParams()


  return(

  <>
    <Helmet>
      <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
    </Helmet>

    <ContentFormat 
      
      name = {metadata.name}
      header = {{ size: 'small', show: true, data: 
        <> <WebbHeader data={{name: metadata.name, home: '/', link:'home'}} /> </> 
      }}
      media = {{ size: 'fluid', show: false, data: <></> }}

      content = {{ size: 'small', show: true, data: 
      <>
        <div className="mb-2"></div>
        <h2 className='text-normal m-0 ms-3'>
          {list.find(x => x.code == id).name}
          {'  Digital Assets'}
        </h2>
        <WebbDividerSmall />
        <CredentialsListUserModule />


        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
      </>
      }}
    
      footer = {{ size: 'small', show: true, data: 
      <> 
        <div className="text-center back-color-lite">
          
          <WebbFooterMobile /> 
        </div>
      </> }}
    
    ></ContentFormat>


  </>
  )
}